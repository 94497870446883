@tailwind base;
@tailwind components;
@tailwind utilities;

/* fade in animation */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn ease 300ms;
}

/* fade and pop in */

@keyframes fadeAndPopIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.fade-and-pop-in {
  animation: fadeAndPopIn ease 300ms;
}
